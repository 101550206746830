<template>
  <div class="container">

  </div>
</template>

<script>
  export default {
    data() {
      return {

      }
    },
    methods: {
      
    }
  }
</script>

<style scoped>

</style>